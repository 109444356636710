import { Container, Typography } from "@mui/material";
import React from "react";

import { motion } from "framer-motion";

export const TextHeading = ({ text }) => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: 2,
      }}
    >
      <motion.div
        initial={{ y: -100, opacity: 0 }} // Inicia fora da tela e invisível
        animate={{ y: 0, opacity: 1 }} // Termina na posição original e visível
        transition={{ duration: 0.5 }} // Duração da animação
      >
        <Typography
          variant="button"
          fontWeight="600"
          color="primary"
          fontSize={30}
        >
          {text}
        </Typography>
      </motion.div>
    </Container>
  );
};
