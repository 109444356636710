import { register } from "swiper/element/bundle";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./styles.css";

import { Swiper } from "swiper/react";
import { useEffect, useState } from "react";
import { Pagination } from "swiper/modules";
import { useTheme } from "@mui/material";

register();

export const CardSlider = ({ children }) => {
  const theme = useTheme();
  const [sliderPerView, setSliderPerView] = useState(4);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setSliderPerView(1);
      } else if (window.innerWidth < 1024) {
        setSliderPerView(2);
      } else if (window.innerWidth < 1440) {
        setSliderPerView(3);
      } else {
        setSliderPerView(4);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Swiper
      style={{
        "--swiper-navigation-color": theme.palette.primary.main,
        "--swiper-pagination-color": theme.palette.primary.main,
        padding: "10px",
      }}
      slidesPerView={sliderPerView}
      navigation
      modules={[Pagination]}
    >
      {children}
    </Swiper>
  );
};
