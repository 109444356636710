import {
  Box,
  Button,
  Divider,
  Icon,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DialogForm } from "../dialogForm/DialogForm";
import { useFavorite } from "../../contexts";

export const ImovelDetalhes = ({ imovelDados, id }) => {
  const [imovel, setImovel] = useState(imovelDados);
  const theme = useTheme();
  const { isFavorite, addFavorite, removeFavorite } = useFavorite();
  const isFavorited = isFavorite(imovel?.id);

  const [dialogProps, setDialogProps] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
    loading: false,
    imovel: null,
  });

  const handleFavorite = (id) => {
    if (isFavorited) {
      removeFavorite(id);
    } else {
      addFavorite(id);
    }
  };

  useEffect(() => {
    console.log("Mudei");
    setImovel(imovelDados);
  }, [imovelDados]);

  return (
    <Paper
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        padding: 2,
        gap: 1,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            width: "120px",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            borderRadius: "5px",
          }}
        >
          <Typography
            variant="caption"
            fontWeight={500}
            color={theme.palette.primary.contrastText}
          >
            {imovel?.tipo.toUpperCase()}
          </Typography>
        </Box>
        <IconButton
          onClick={() => {
            handleFavorite(imovel?.id);
          }}
        >
          <Icon color={isFavorited ? "error" : ""}>
            {isFavorited ? "favorite" : "favorite_border"}
          </Icon>
        </IconButton>
      </Box>

      <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <Typography variant="caption" fontWeight={600}>
          Código: {imovel?.codigo}
        </Typography>
        <Typography variant="h6" fontWeight={600}>
          {imovel?.imovel}
        </Typography>

        <Box sx={{ display: "flex", flexWrap: "wrap", my: 3 }}>
          <Icon>location_on</Icon>
          <Typography variant="body1" fontWeight={500}>
            Endereço:
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography ml={1} variant="body1">
              {`${imovel?.endereco}, ${imovel?.numero}, ${imovel?.bairro}`}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography ml={1} variant="body1">
              {`${imovel?.cidade} / ${imovel?.uf} - ${imovel?.cep}`}
            </Typography>
          </Box>
        </Box>
        {imovel?.observacoes && (
          <Typography>- {imovel?.observacoes}</Typography>
        )}
      </Box>
      {(imovel?.banheiros ||
        imovel?.quartos ||
        imovel?.garagem ||
        imovel?.observacoes) && (
        <Stack
          direction="row"
          spacing={1}
          my={2}
          divider={<Divider orientation="vertical" flexItem />}
        >
          {imovel?.quartos && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Icon fontSize="small">bed</Icon>
              <Typography variant="caption" fontWeight={500}>
                {imovel?.quartos}
              </Typography>
              <Typography variant="caption">quarto(s)</Typography>
            </Box>
          )}
          {imovel?.banheiros && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Icon fontSize="small">bathtub</Icon>
              <Typography variant="caption" fontWeight={500}>
                {imovel?.banheiros}
              </Typography>
              <Typography variant="caption">banheiro(s)</Typography>
            </Box>
          )}
          {imovel?.garagem && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Icon fontSize="small">garage</Icon>
              <Typography variant="caption" fontWeight={500}>
                {imovel?.garagem}
              </Typography>
              <Typography variant="caption">garagem(s)</Typography>
            </Box>
          )}
          {imovel?.metragem && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Icon fontSize="small">square_foot</Icon>
              <Typography variant="caption" fontWeight={500}>
                {imovel?.metragem}
              </Typography>
              <Typography variant="caption">Mt(2)</Typography>
            </Box>
          )}
        </Stack>
      )}

      <Box>
        <Typography variant="button">Apartir de:</Typography>
        <Typography variant="h5" fontWeight={600} color="primary">
          {`${imovel?.valor === "R$ 0,00" ? "Sob consulta" : imovel?.valor} ${
            imovel?.motivo === "aluguel" && "/ mês"
          }`}
        </Typography>
        {imovel?.valorCondominio !== "R$ 0,00" && (
          <Typography
            fontSize={14}
            fontWeight={500}
          >{`Condomínio: ${imovel?.valorCondominio}`}</Typography>
        )}
        <Button
          variant="contained"
          sx={{ mt: 2 }}
          onClick={() => {
            setDialogProps({
              ...dialogProps,
              isOpen: true,
              title: "Está interessado?",
              subTitle: "Preencha os dados que entraremos em contato com você",
              imovel: imovel,
            });
          }}
        >
          QUERO ESSE IMÓVEL
        </Button>
      </Box>
      <DialogForm dialogProps={dialogProps} setDialogProps={setDialogProps} />
    </Paper>
  );
};
