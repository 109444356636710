import { Box, CssBaseline, Toolbar } from "@mui/material";
import { AppHeader, Footer, WhatsAppFab } from "../components";
import { useEffect } from "react";

export const BaseDePagina = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll para o topo quando a página é carregada
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100dvw",
        overflowX: "hidden", // Adicionado para evitar overflow no eixo X
        maxWidth: "100%", // Adicionado para garantir que o Box não exceda a largura da viewport
      }}
    >
      <CssBaseline />
      <AppHeader />
      <Toolbar />
      {children}
      <Footer />
      <WhatsAppFab />
    </Box>
  );
};
