import {
  Box,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { PhotoSlider } from "../photoSlider/PhotoSlider";

export const ImovelBox = ({ dados }) => {
  const fotos = dados.fotos;

  return (
    <Box component={Paper} sx={{ display: "flex", width: "100%", mb: 3 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
          <PhotoSlider fotos={fotos} />
        </Grid>
        <Grid item xs={12} md={8} p={2}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              px: 1,
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="h6" fontSize={18}>
                {dados.imovel}
              </Typography>
              <IconButton>
                <Icon>favorite_border</Icon>
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", flex: 1, height: "100%" }}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Typography variant="caption" fontSize={14}>
                      {`${dados.endereco},${dados.numero},${dados.bairro} - ${dados.cidade}/${dados.uf}`}
                    </Typography>
                    <Typography variant="caption" fontSize={14}>
                      {dados?.edificio}
                    </Typography>
                    <Typography
                      variant="caption"
                      fontSize={12}
                      fontWeight={600}
                    >
                      {`Cod. ${dados.codigo}`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      textAlign: "end",
                    }}
                  >
                    <Typography variant="h6" fontSize={16}>
                      {dados.motivo.toUpperCase()}
                    </Typography>
                    <Typography variant="h6" fontSize={18} color="error">
                      {dados.valor === "R$ 0,00" ? "Sob Consulta" : dados.valor}{" "}
                      {dados.motivo === "aluguel" && "/ mês"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={{ xs: 1, sm: 2 }}
                useFlexGap
                flexWrap="wrap"
              >
                {dados.quartos && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Icon fontSize="small" color="inherit">
                      bed
                    </Icon>
                    <Typography fontSize={12}>{dados.quartos}</Typography>
                    <Typography variant="caption">Quartos</Typography>
                  </Box>
                )}
                {dados.banheiros && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Icon fontSize="small" color="inherit">
                      bathtub
                    </Icon>
                    <Typography fontSize={12}>{dados.banheiros}</Typography>
                    <Typography variant="caption">Banheiros</Typography>
                  </Box>
                )}
              </Stack>
              <Button sx={{ height: "30px" }} variant="contained">
                Saber mais
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
