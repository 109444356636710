import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { SemDados } from "../semDados/SemDados";

export const ImovelFotos = ({ fotos }) => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (fotos.length > 0) {
      const photoArray = fotos.map((foto) => ({
        original: foto,
        thumbnail: foto,
      }));
      setPhotos(photoArray);
    }
    setLoading(false);
  }, [fotos]);

  if (loading) {
    return <CircularProgress />;
  }

  if (photos.length > 0) {
    return <ImageGallery showIndex={true} items={photos} />;
  } else {
    return <SemDados text="Fotos em breve..." icon="hide_image" />;
  }
};
