import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  Box,
  Icon,
} from "@mui/material";
import { BaseDePagina } from "../../layout/BaseDePagina";
import { TextHeading } from "../../components";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import useImoveis from "../../hooks/useImoveis";
import { useLoading } from "../../contexts";
import { mask, unMask } from "remask";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const mapContainerStyle = {
  width: "100%",
  height: "300px",
};

const center = {
  lat: -28.71391325069855,
  lng: -49.29910076048486,
};

export const Contato = () => {
  const [info, setInfo] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm();

  const { loading } = useLoading();
  const { enviarMensagem, getSiteInfo } = useImoveis();

  const telefone = watch("telefone") || "";

  const handleGetInfo = async () => {
    const result = await getSiteInfo();
    setInfo(result);
  };

  const onSubmit = async (data) => {
    const formData = {
      ...data,
      dataCriacao: new Date().toISOString(),
    };
    enviarMensagem(formData);
    reset();
  };

  useEffect(() => {
    const formattedTelefone = mask(unMask(telefone || ""), [
      "(99) 9999-9999",
      "(99) 99999-9999",
    ]);
    if (formattedTelefone !== telefone) {
      setValue("telefone", formattedTelefone);
    }
  }, [telefone, setValue]);

  useEffect(() => {
    handleGetInfo();
  }, []);

  return (
    <BaseDePagina>
      <TextHeading text="Contato" />
      <Container sx={{ padding: 4, mb: 3 }} component={Paper}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography fontStyle="oblique" sx={{ mb: 2 }}>
                Preencha o formulário abaixo, e envie sua mensagem!
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Nome"
                    fullWidth
                    {...register("nome", { required: "Nome é obrigatório" })}
                    error={!!errors.nome}
                    helperText={errors.nome?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Telefone"
                    fullWidth
                    {...register("telefone", {
                      required: "Telefone é obrigatório",
                    })}
                    error={!!errors.telefone}
                    helperText={errors.telefone?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    fullWidth
                    {...register("email", {
                      required: "Email é obrigatório",
                      pattern: {
                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                        message: "Email inválido",
                      },
                    })}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Assunto"
                    fullWidth
                    {...register("assunto", {
                      required: "Assunto é obrigatório",
                    })}
                    error={!!errors.assunto}
                    helperText={errors.assunto?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Mensagem"
                    fullWidth
                    multiline
                    rows={4}
                    {...register("mensagem", {
                      required: "Mensagem é obrigatória",
                    })}
                    error={!!errors.mensagem}
                    helperText={errors.mensagem?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                  >
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={1}>
              <Typography
                variant="button"
                fontWeight={600}
                mb={1}
                fontSize={15}
              >
                Telefones:
              </Typography>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <WhatsAppIcon />
                <Typography>{info?.whatsapp}</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Icon>phone</Icon>
                <Typography>{info?.telefone}</Typography>
              </Box>
            </Box>
            <Box mb={1}>
              <Typography
                variant="button"
                fontWeight={600}
                mb={1}
                fontSize={15}
              >
                Email:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  marginBottom: 2,
                }}
              >
                {info?.emailPrincipal && (
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Icon>mail</Icon>
                    <Typography>{info?.emailPrincipal}</Typography>
                  </Box>
                )}
                {info?.emailSecundario && (
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Icon>mail</Icon>
                    <Typography>{info?.emailSecundario}</Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={{ mb: 1 }}>
              <Typography
                variant="button"
                fontWeight={600}
                mb={1}
                fontSize={15}
              >
                Endreço:
              </Typography>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Icon>location_on</Icon>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography>{`${info?.enderecoLogradouro}, ${info?.enderecoNumero}, ${info?.enderecoBairro}`}</Typography>
                  <Typography>{`${info?.enderecoCep} - ${info?.enderecoCidade}/${info?.enderecoUf}`}</Typography>
                  <Typography
                    fontSize={14}
                  >{`${info?.enderecoComplemento}`}</Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <LoadScript googleMapsApiKey="AIzaSyBoqMWvJPE_iH3hTQSnWzQULwOwvxz0fsI">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={center}
                  zoom={15}
                >
                  <Marker position={center} />
                </GoogleMap>
              </LoadScript>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </BaseDePagina>
  );
};
