import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Importar useNavigate
import useImoveis from "../../hooks/useImoveis";
import { CardImoveis } from "../cardImoveis/CardImoveis";
import { CardSlider } from "../cardSlider/CardSlider";
import { SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

export const Destaques = () => {
  const [destaques, setDestaques] = useState([]);

  const { getImoveisDestaque } = useImoveis();
  const navigate = useNavigate(); // Usar useNavigate

  const handleGetDestaques = async () => {
    await getImoveisDestaque().then((result) => {
      setDestaques(result);
    });
  };

  useEffect(() => {
    handleGetDestaques();
  }, []);

  const handleVerTodosClick = () => {
    navigate("/imoveis"); // Navegar para /imoveis
  };

  if (destaques.length > 0) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: 4,
          mb: 4,
        }}
      >
        <Typography
          variant="button"
          fontSize={36}
          fontWeight={600}
          color="primary"
          mb={5}
        >
          Destaques
        </Typography>

        <CardSlider>
          {destaques?.map((val, i) => (
            <SwiperSlide key={i}>
              <CardImoveis dados={val} />
            </SwiperSlide>
          ))}
        </CardSlider>

        <Button
          variant="contained"
          sx={{ mt: 6 }}
          onClick={handleVerTodosClick}
        >
          Ver todos imóveis
        </Button>
      </Box>
    );
  }

  return null;
};
