import { createTheme } from "@mui/material";
import { purple, blue, orange } from "@mui/material/colors";

export const DarkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: orange[700],
      dark: orange[800],
      light: orange[500],
      contrastText: "#fff",
    },
    secondary: {
      main: blue[500],
      dark: blue[400],
      light: blue[300],
      contrastText: "#fff",
    },
    background: {
      default: "#202124",
      paper: "#303134",
    },
  },
  typography: {
    allVariants: {
      color: "white",
    },
  },
});
