import { Box, Icon, Typography, useTheme } from "@mui/material";

export const SemDados = ({ icon, text }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 2,
        my: 8,
      }}
    >
      <Icon sx={{ fontSize: "8rem", color: theme.palette.primary.main }}>
        {icon}
      </Icon>
      <Typography
        fontSize={18}
        fontWeight={500}
        variant="button"
        color={theme.palette.primary.main}
      >
        {text}
      </Typography>
    </Box>
  );
};
