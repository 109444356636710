import { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

export const Mapa = ({ endereco }) => {
  const apiKey = "AIzaSyBoqMWvJPE_iH3hTQSnWzQULwOwvxz0fsI";
  const mapContainerStyle = {
    width: "100%",
    height: "300px",
  };

  const [center, setCenter] = useState(null);

  useEffect(() => {
    const getCoordinates = async (address) => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=${apiKey}`
        );
        const data = await response.json();
        if (data.status === "OK" && data.results.length > 0) {
          const location = data.results[0].geometry.location;
          setCenter(location);
        } else {
          console.error(
            "Geocode was not successful for the following reason:",
            data.status
          );
        }
      } catch (error) {
        console.error("Error fetching geocode data:", error);
      }
    };

    if (endereco) {
      getCoordinates(endereco);
    }
  }, [endereco, apiKey]);

  return (
    <LoadScript
      googleMapsApiKey={apiKey}
      loadingElement={<div>Loading...</div>}
    >
      {center ? (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={15}
        >
          <Marker position={center} />
        </GoogleMap>
      ) : (
        <div>Loading...</div>
      )}
    </LoadScript>
  );
};
