import { useState } from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  Button,
  useTheme,
  Icon,
  Stack,
  LinearProgress,
  Badge,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ThemeSwitch } from "../themeSwitch/ThemeSwitch";
import { useLoading } from "../../contexts/LoadingContext";
import { useFavorite, useFavoriteDrawer } from "../../contexts";

const drawerWidth = 240;
const navItems = [
  { text: "Inicio", path: "/" },
  { text: "Sobre Nós", path: "/sobre" },
  { text: "Imóveis", path: "/imoveis" },
  { text: "Contato", path: "/contato" },
];

export const AppHeader = (props) => {
  const { window } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const { loading } = useLoading();
  const { favorites } = useFavorite();
  const { toggleDrawer } = useFavoriteDrawer();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        FORTULINO IMÓVEIS
      </Typography>
      <Divider />
      <List onClick={handleDrawerToggle}>
        {navItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              component={Link}
              to={item.path}
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <Icon>menu</Icon>
          </IconButton>

          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block", cursor: "pointer" },
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              src={require("../../assets/logoHorinzontalWhite.png")}
              alt="logo-horizontal"
              width="150px"
            />
          </Typography>

          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item.text}
                component={Link}
                to={item.path}
                sx={{
                  color: theme.palette.primary.contrastText,
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              width: { xs: "100%", md: "100px" },
            }}
          >
            <IconButton color="inherit" onClick={() => toggleDrawer()}>
              <Badge
                color="error"
                badgeContent={favorites.length}
                variant="standard"
              >
                <Icon color="inherit">favorite</Icon>
              </Badge>
            </IconButton>
            <ThemeSwitch />
          </Box>
        </Toolbar>
        {loading && <LinearProgress color="primary" />}
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};
