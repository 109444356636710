import {
  Box,
  Dialog,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import useImoveis from "../../hooks/useImoveis";
import { useEffect, useState } from "react";
import { mask, unMask } from "remask";

export const DialogForm = ({ dialogProps, setDialogProps }) => {
  const [loading, setLoading] = useState(false);
  const { enviarMensagem } = useImoveis();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm();

  const telefone = watch("telefone") || "";

  useEffect(() => {
    const formattedTelefone = mask(unMask(telefone || ""), [
      "(99) 9999-9999",
      "(99) 99999-9999",
    ]);
    if (formattedTelefone !== telefone) {
      setValue("telefone", formattedTelefone);
    }
  }, [telefone, setValue]);

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = {
      ...data,
      dataCriacao: new Date().toISOString(),
      assunto: dialogProps.imovel.imovel,
      id: dialogProps.imovel.id,
    };

    await enviarMensagem(formData).then(() => {
      setLoading(false);
      setDialogProps({ ...dialogProps, isOpen: false });
      reset();
    });
  };

  const handleClose = () => {
    setDialogProps({
      ...dialogProps,
      isOpen: false,
    });
  };

  return (
    <Dialog
      open={dialogProps.isOpen}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Typography variant="button" color="primary" fontSize={22}>
            {dialogProps.title}
          </Typography>
          <Typography variant="caption" fontSize={16}>
            {dialogProps.subTitle}
          </Typography>
        </Box>
      </DialogTitle>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}
      >
        <TextField
          label="Nome"
          {...register("nome", { required: "Nome é obrigatório" })}
          error={!!errors.nome}
          helperText={errors.nome ? errors.nome.message : ""}
          fullWidth
        />
        <TextField
          label="Telefone"
          value={telefone}
          {...register("telefone", { required: "Telefone é obrigatório" })}
          onChange={(e) =>
            setValue(
              "telefone",
              mask(unMask(e.target.value), ["(99) 99999-9999"])
            )
          }
          error={!!errors.telefone}
          helperText={errors.telefone ? errors.telefone.message : ""}
          fullWidth
        />
        <TextField
          label="Email"
          {...register("email", {
            required: "Email é obrigatório",
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: "Email inválido",
            },
          })}
          error={!!errors.email}
          helperText={errors.email ? errors.email.message : ""}
          fullWidth
        />
        <TextField
          label="Mensagem"
          defaultValue={`Olá, quero esse imóvel, ${dialogProps.imovel?.tipo}, ${dialogProps.imovel?.imovel} - ${dialogProps.imovel?.bairro}, ${dialogProps.imovel?.cidade}. Localizado em ${dialogProps.imovel?.endereco}`}
          {...register("mensagem", { required: "Mensagem é obrigatória" })}
          error={!!errors.mensagem}
          helperText={errors.mensagem ? errors.mensagem.message : ""}
          multiline
          rows={4}
          fullWidth
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Enviar
        </Button>
      </Box>
    </Dialog>
  );
};
