import React, { useEffect, useState } from "react";
import { Fab } from "@mui/material";
import { keyframes } from "@emotion/react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import useImoveis from "../../hooks/useImoveis";

const message = "Olá, gostaria de saber mais informações sobre imóveis.";

export const WhatsAppFab = () => {
  const [info, setInfo] = useState(null);
  const { getSiteInfo } = useImoveis();

  const handleClick = () => {
    const url = `https://web.whatsapp.com/send?phone=${
      info?.whatsapp
    }&text=${encodeURIComponent(message)}`;
    window.open(url, "_blank");
  };

  const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

  const handleGetInfo = async () => {
    const result = await getSiteInfo();
    setInfo(result);
  };

  useEffect(() => {
    handleGetInfo();
  }, []);

  return (
    <Fab
      size="large"
      sx={{
        position: "fixed",
        bottom: 30,
        right: 30,
        backgroundColor: "#25D366",
        "&:hover": {
          backgroundColor: "#1DA851",
        },
        color: "white",
        animation: `${pulse} 1.5s infinite`,
      }}
      onClick={handleClick}
    >
      <WhatsAppIcon fontSize="large" />
    </Fab>
  );
};
