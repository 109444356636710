import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Icon,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useState, useEffect } from "react";
import HideImageIcon from "@mui/icons-material/HideImage";
import { useFavorite } from "../../contexts";

export const CardImoveis = ({ dados }) => {
  const theme = useTheme();
  const [loadingImage, setLoadingImage] = useState(true);

  const { isFavorite, addFavorite, removeFavorite } = useFavorite();
  const isFavorited = isFavorite(dados?.id);

  useEffect(() => {
    if (dados?.fotoDestaque) {
      const img = new Image();
      img.src = dados.fotoDestaque;
      img.onload = () => setLoadingImage(false);
      img.onerror = () => setLoadingImage(false);
    } else {
      setLoadingImage(false);
    }
  }, [dados?.fotoDestaque]);

  const handleVerMaisClick = () => {
    window.open(`/imovel/${dados.id}`, "_blank");
  };

  const handleFavorite = (id) => {
    if (isFavorited) {
      removeFavorite(id); // Remove dos favoritos
    } else {
      addFavorite(id); // Adiciona aos favoritos
    }
  };

  return (
    <Card
      sx={{
        width: 350,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", width: "100%" }}>
        {loadingImage ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "200px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : dados?.fotoDestaque ? (
          <img src={dados?.fotoDestaque} alt="foto-destaque" loading="lazy" />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "200px",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#ccc",
              gap: 2,
            }}
          >
            <HideImageIcon fontSize="large" />
            <Typography variant="button">Sem foto destaque</Typography>
          </Box>
        )}
      </Box>
      <CardContent
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography variant="caption">Código: </Typography>
          <Typography variant="caption" fontWeight={600}>
            {dados.codigo}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: 2, my: 1 }}>
          <Typography
            variant="button"
            fontSize={12}
            bgcolor={theme.palette.grey[200]}
            color={theme.palette.grey[700]}
            px={1}
            borderRadius={1}
          >
            {dados?.tipo}
          </Typography>
          <Typography
            variant="button"
            fontSize={12}
            bgcolor={theme.palette.primary.main}
            color={theme.palette.primary.contrastText}
            px={1}
            borderRadius={1}
          >
            {dados?.motivo}
          </Typography>
        </Box>

        <Typography
          variant="h6"
          component="div"
          color="primary"
          fontWeight={600}
        >
          {`${dados.valor === "R$ 0,00" ? "Sob consulta" : dados.valor} ${
            dados.motivo === "aluguel" ? "/ mês" : ""
          }`}
        </Typography>
        <Typography variant="button" color="text.secondary">
          {dados.imovel}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {dados.endereco} - {dados.bairro} - {dados.cidade}/{dados.uf}
        </Typography>

        {(dados.banheiros ||
          dados.quartos ||
          dados.garagem ||
          dados.observacoes) && (
          <Stack
            direction="row"
            spacing={1}
            my={2}
            divider={<Divider orientation="vertical" flexItem />}
          >
            {dados.quartos && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Icon fontSize="small">bed</Icon>
                <Typography variant="caption" fontWeight={500}>
                  {dados.quartos}
                </Typography>
                <Typography variant="caption">quarto(s)</Typography>
              </Box>
            )}
            {dados.banheiros && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Icon fontSize="small">bathtub</Icon>
                <Typography variant="caption" fontWeight={500}>
                  {dados.banheiros}
                </Typography>
                <Typography variant="caption">banheiro(s)</Typography>
              </Box>
            )}
            {dados.garagem && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Icon fontSize="small">garage</Icon>
                <Typography variant="caption" fontWeight={500}>
                  {dados.garagem}
                </Typography>
                <Typography variant="caption">garagem(s)</Typography>
              </Box>
            )}
            {dados.metragem && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Icon fontSize="small">square_foot</Icon>
                <Typography variant="caption" fontWeight={500}>
                  {dados.metragem}
                </Typography>
                <Typography variant="caption">Mt(2)</Typography>
              </Box>
            )}
          </Stack>
        )}
        <Typography variant="caption">{dados.observacoes}</Typography>
      </CardContent>
      <CardActions
        sx={{
          display: "flex",
          px: 2,
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          size="small"
          startIcon={<Icon>visibility</Icon>}
          onClick={handleVerMaisClick}
        >
          Ver mais
        </Button>
        <IconButton
          onClick={() => {
            handleFavorite(dados?.id);
          }}
        >
          <Icon color={isFavorited ? "error" : ""}>
            {isFavorited ? "favorite" : "favorite_border"}
          </Icon>
        </IconButton>
      </CardActions>
    </Card>
  );
};
