import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

const FavoriteContext = createContext();

export const useFavorite = () => {
  return useContext(FavoriteContext);
};

export const FavoriteProvider = ({ children }) => {
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    const storedFavorites = Cookies.get("@FAVORITES")
      ? JSON.parse(Cookies.get("@FAVORITES"))
      : [];
    setFavorites(storedFavorites);
  }, []);

  useEffect(() => {
    Cookies.set("@FAVORITES", JSON.stringify(favorites), { expires: 2 });
  }, [favorites]);

  const addFavorite = (id) => {
    if (!favorites.includes(id)) {
      setFavorites([...favorites, id]);
    }
  };

  const removeFavorite = (id) => {
    setFavorites(favorites.filter((favId) => favId !== id));
  };

  const isFavorite = (id) => {
    return favorites.includes(id);
  };

  return (
    <FavoriteContext.Provider
      value={{ favorites, addFavorite, removeFavorite, isFavorite }}
    >
      {children}
    </FavoriteContext.Provider>
  );
};
