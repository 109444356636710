import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./routes";
import { AppThemeProvider } from "./contexts/ThemeContext";
import { ToastContainer } from "react-toastify";
import {
  FavoriteProvider,
  LoadingProvider,
  FavoriteDrawerProvider,
} from "./contexts";

import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <AppThemeProvider>
      <ToastContainer />
      <BrowserRouter>
        <LoadingProvider>
          <FavoriteProvider>
            <FavoriteDrawerProvider>
              <AppRoutes />
            </FavoriteDrawerProvider>
          </FavoriteProvider>
        </LoadingProvider>
      </BrowserRouter>
    </AppThemeProvider>
  );
}

export default App;
