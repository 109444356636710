import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  Grid,
  Icon,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { useFavorite } from "./FavoriteContext";
import useImoveis from "../hooks/useImoveis";

const FavoriteDrawerContext = createContext();

export const useFavoriteDrawer = () => {
  return useContext(FavoriteDrawerContext);
};

export const FavoriteDrawerProvider = ({ children }) => {
  const theme = useTheme();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [imoveis, setImoveis] = useState([]);

  const { favorites } = useFavorite();
  const { getImovelById } = useImoveis();

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const handleGetFavorites = async () => {
    let result = [];

    for (let i = 0; i < favorites.length; i++) {
      const element = favorites[i];
      const response = await getImovelById(element);
      result.push(response);
    }

    setImoveis(result);
  };

  const handleVerMaisClick = (id) => {
    window.open(`/imovel/${id}`, "_blank");
  };

  useEffect(() => {
    if (favorites.length > 0) {
      handleGetFavorites();
    }
  }, [favorites]);

  return (
    <FavoriteDrawerContext.Provider
      value={{ isDrawerOpen, openDrawer, closeDrawer, toggleDrawer }}
    >
      {children}

      <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: 350, md: 500 },
            padding: 2,
            gap: 2,
            overflowY: "auto",
            height: "100%",
            bgcolor: "#F7F6F3",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Icon color="error">favorite</Icon>
              <Typography variant="h5" color="primary" fontWeight={550}>
                Favoritos
              </Typography>
            </Box>
            <IconButton onClick={closeDrawer}>
              <Icon>close</Icon>
            </IconButton>
          </Box>

          {favorites.length > 0 ? (
            <>
              {imoveis.map((val) => (
                <Paper key={val.id} sx={{ padding: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          height: { xs: 180, md: "100%" },
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          variant="square"
                          src={val.fotoDestaque}
                          sx={{ width: "100%", height: "100%" }}
                        >
                          <Icon>photo</Icon>
                        </Avatar>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Typography
                            variant="button"
                            fontSize={11}
                            sx={{
                              bgcolor: theme.palette.grey[200],
                              color: "GrayText",
                              px: 1,
                              borderRadius: 1,
                            }}
                          >
                            {val?.tipo}
                          </Typography>
                          <Typography
                            variant="button"
                            fontSize={11}
                            sx={{
                              bgcolor: theme.palette.primary.main,
                              color: theme.palette.primary.contrastText,
                              px: 1,
                              borderRadius: 1,
                            }}
                          >
                            {val?.motivo}
                          </Typography>
                        </Box>
                        <Box sx={{ my: 1 }}>
                          <Typography
                            variant="body2"
                            fontSize={12}
                            fontWeight={500}
                          >
                            {val?.imovel}
                          </Typography>
                          <Typography variant="caption" fontSize={11}>
                            {`${val.endereco} - ${val.cidade}/${val.uf}`}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="caption" fontWeight={500}>
                              Valor
                            </Typography>
                            <Typography variant="caption">
                              {`${
                                val.valor === "R$ 0,00"
                                  ? "Sob consulta"
                                  : val.valor
                              } ${val.motivo === "aluguel" ? "/ mês" : ""}`}
                            </Typography>
                          </Box>

                          <Button
                            size="small"
                            onClick={() => handleVerMaisClick(val.id)}
                          >
                            Ver imóvel
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              ))}
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              <Typography fontStyle="italic">
                Essa é a sua lista de favoritos...
              </Typography>
            </Box>
          )}
        </Box>
      </Drawer>
    </FavoriteDrawerContext.Provider>
  );
};
