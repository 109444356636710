import { Route, Routes, Navigate } from "react-router-dom";

import { Buscar, Contato, Home, Imoveis, Imovel, Sobre } from "../pages";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/sobre" element={<Sobre />} />
      <Route path="/imoveis" element={<Imoveis />} />
      <Route path="/contato" element={<Contato />} />
      <Route path="/buscar" element={<Buscar />} />
      <Route path="/imovel/:id" element={<Imovel />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
