import { Box, Typography, keyframes } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { Pesquisar } from "../pesquisar/Pesquisar";

const trackingInExpand = keyframes`
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
`;

const Title = styled(Typography)(() => ({
  fontSize: 40,
  color: "#fff",
  fontWeight: "bold",
  animation: `${trackingInExpand} 2s`,
}));

export const Banner = () => {
  return (
    <Box
      sx={{
        backgroundImage:
          "url('https://images.unsplash.com/photo-1560448204-e02f11c3d0e2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        width: "100%",
        height: "95dvh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:before": {
          backgroundColor: "rgba(0,0,0,0.6)",
          content: "''",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: 2,
          width: { sm: "100%", md: "60%" },
          display: "flex",
          flexDirection: "column",
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            padding: 5,
          }}
        >
          <img
            src={require("../../assets/logoWhite.png")}
            alt=""
            width="300px"
          />
        </Box>
        <Title>ENCONTRE SUA SOLUÇÃO HOJE MESMO</Title>
        <Pesquisar />
      </Box>
    </Box>
  );
};
