import { Box } from "@mui/material";
import { Filtro, ImoveisList, TextHeading } from "../../components";
import { BaseDePagina } from "../../layout/BaseDePagina";
import { useEffect, useRef, useState } from "react";
import useImoveis from "../../hooks/useImoveis";
import useFiltro from "../../hooks/useFiltro";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const Imoveis = () => {
  const location = useLocation();

  const { buscaMotivo, buscaTipo, buscaCidade } = queryString.parse(
    location.search
  );

  const [loading, setLoading] = useState(false);
  const [imoveis, setImoveis] = useState([]);
  const [filteredImoveis, setFilteredImoveis] = useState([]);

  const [motivo, setMotivo] = useState("");
  const [pageLimit, setPageLimit] = useState(12);
  const [page, setPage] = useState(1);

  const lastDocs = useRef([]);

  const { getImoveis } = useImoveis();

  const {
    resetFiltro,
    tipos,
    handleTipoChange,
    selectedTipos,
    cidades,
    handleCidadeChange,
    selectedCidades,
    bairros,
    handleBairroChange,
    selectedBairros,
    setSelectedTipos,
    setSelectedCidades,
  } = useFiltro(imoveis, buscaTipo, buscaCidade);

  const loadImoveis = async (page) => {
    setLoading(true);
    const lastDoc = lastDocs.current[page - 2] || null;
    const result = await getImoveis(pageLimit, lastDoc, motivo);
    setImoveis(result.dataList);
    lastDocs.current[page - 1] = result.lastVisible;
    setLoading(false);
  };

  const filterImoveis = () => {
    let result = imoveis;

    if (result.length > 0) {
      if (selectedTipos.length > 0) {
        result = result.filter((imovel) => selectedTipos.includes(imovel.tipo));
      }
      if (selectedCidades.length > 0) {
        result = result.filter((imovel) =>
          selectedCidades.includes(imovel.cidade)
        );
      }
      if (selectedBairros.length > 0) {
        result = result.filter((imovel) =>
          selectedBairros.includes(imovel.bairro)
        );
      }

      setFilteredImoveis(result);
    } else {
      setFilteredImoveis([]);
    }
  };

  useEffect(() => {
    if (buscaMotivo) {
      setMotivo(buscaMotivo);
    }
    if (buscaTipo) {
      setSelectedTipos((prev) =>
        prev.includes(buscaTipo)
          ? prev.filter((t) => t !== buscaTipo)
          : [...prev, buscaTipo]
      );
    }
    if (buscaCidade) {
      setSelectedCidades((prev) =>
        prev.includes(buscaCidade)
          ? prev.filter((c) => c !== buscaCidade)
          : [...prev, buscaCidade]
      );
    }
  }, []);

  useEffect(() => {
    filterImoveis();
  }, [selectedTipos, selectedCidades, selectedBairros, imoveis, motivo]);

  useEffect(() => {
    loadImoveis(page);
  }, [page, motivo]);

  return (
    <BaseDePagina>
      <TextHeading text="O que você procura?" />
      <Box sx={{ maxWidth: "1600px", width: "100%", display: "flex", mb: 3 }}>
        <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              width: "400px",
              padding: 1,
            }}
          >
            <Filtro
              resetFiltro={resetFiltro}
              tipos={tipos}
              handleTipoChange={handleTipoChange}
              selectedTipos={selectedTipos}
              cidades={cidades}
              handleCidadeChange={handleCidadeChange}
              selectedCidades={selectedCidades}
              bairros={bairros}
              handleBairroChange={handleBairroChange}
              selectedBairros={selectedBairros}
              motivo={motivo}
              setMotivo={setMotivo}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: { xs: "100%", md: "calc(100% - 400px)" },
              padding: 1,
              flexDirection: "column",
            }}
          >
            <ImoveisList
              imoveis={filteredImoveis.length > 0 ? filteredImoveis : imoveis}
              loading={loading}
              page={page}
              pageLimit={pageLimit}
              setPage={setPage}
            />
          </Box>
        </Box>
      </Box>
    </BaseDePagina>
  );
};
