import { Box, Pagination, Stack, Typography } from "@mui/material";
import useImoveis from "../../hooks/useImoveis";
import { useEffect, useState, useRef } from "react";
import { CardImoveis } from "../cardImoveis/CardImoveis";

export const ImoveisList = ({ setPage, loading, imoveis, pageLimit, page }) => {
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      {!loading && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {imoveis.length > 0 ? (
            <>
              <Stack
                spacing={2}
                direction="row"
                useFlexGap
                flexWrap="wrap"
                justifyContent="center"
                sx={{ width: "100%" }}
              >
                {imoveis.map((imovel, i) => (
                  <CardImoveis dados={imovel} key={imovel.id} />
                ))}
              </Stack>
              <Box sx={{ display: "flex", flexDirection: "column", my: 3 }}>
                <Typography mb={2} variant="button">
                  {`Exibindo ${
                    pageLimit < imoveis.length ? pageLimit : imoveis.length
                  } de ${imoveis.length} imóveis`}
                </Typography>
                <Pagination
                  count={Math.ceil(imoveis.length / pageLimit)} // Calcula o número de páginas dinamicamente
                  page={page}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                  color="primary"
                  size="large"
                />
              </Box>
            </>
          ) : (
            <Box></Box>
          )}
        </Box>
      )}
    </>
  );
};
