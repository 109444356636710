import { useParams } from "react-router-dom";
import { BaseDePagina } from "../../layout/BaseDePagina";
import useImoveis from "../../hooks/useImoveis";
import { useEffect, useState } from "react";
import { Container, Grid, Typography, useTheme } from "@mui/material";
import { Destaques, ImovelDetalhes, ImovelFotos, Mapa } from "../../components";

export const Imovel = () => {
  const { id } = useParams();
  const { getImovelById } = useImoveis();
  const [imovel, setImovel] = useState(null);
  const [fotos, setFotos] = useState(imovel?.fotos || []);

  useEffect(() => {
    getImovelById(id).then((result) => {
      setImovel(result);
      setFotos(result.fotos);
    });
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <BaseDePagina>
      <Container
        sx={{
          padding: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Grid container spacing={{ xs: 2, md: 4 }}>
          <Grid item xs={12} md={6}>
            <ImovelFotos fotos={fotos} />
          </Grid>
          <Grid item xs={12} md={6}>
            <ImovelDetalhes imovelDados={imovel} id={id} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="button" fontSize={20} fontWeight={600}>
              Localização
            </Typography>
            <Mapa
              endereco={`${imovel?.endereco}, ${imovel?.numero}, ${imovel?.bairro}, ${imovel?.cidade}, ${imovel?.uf}, ${imovel?.cep}`}
            />
          </Grid>
        </Grid>
      </Container>
    </BaseDePagina>
  );
};
