import { createTheme } from "@mui/material";
import { purple, blue } from "@mui/material/colors";

export const LightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: blue[600],
      dark: blue[400],
      light: blue[300],
      contrastText: "#fff",
    },
    secondary: {
      main: purple[600],
      dark: purple[400],
      light: purple[300],
      contrastText: "#fff",
    },
    background: {
      default: "#F7F6F3",
      paper: "#fff",
    },
  },
});
