import { useEffect, useState } from "react";

const useFiltro = (imoveis, buscaTipo, buscaCidade) => {
  const [tipos, setTipos] = useState([]);
  const [selectedTipos, setSelectedTipos] = useState([]);

  const [cidades, setCidades] = useState([]);
  const [selectedCidades, setSelectedCidades] = useState([]);

  const [bairros, setBairros] = useState([]);
  const [selectedBairros, setSelectedBairros] = useState([]);

  const updateTipos = () => {
    const tiposContados = imoveis.reduce((acc, imovel) => {
      acc[imovel.tipo] = (acc[imovel.tipo] || 0) + 1;
      return acc;
    }, {});

    const tiposArray = Object.keys(tiposContados).map((tipo) => ({
      nome: tipo,
      qtd: tiposContados[tipo],
    }));

    setTipos(tiposArray);
  };

  const updateCidades = () => {
    const cidadesContados = imoveis
      .filter(
        (imovel) =>
          selectedTipos.length === 0 || selectedTipos.includes(imovel.tipo)
      )
      .reduce((acc, imovel) => {
        acc[imovel.cidade] = (acc[imovel.cidade] || 0) + 1;
        return acc;
      }, {});

    const cidadesArray = Object.keys(cidadesContados).map((cidade) => ({
      nome: cidade,
      qtd: cidadesContados[cidade],
    }));

    setCidades(cidadesArray);
  };

  const updateBairros = () => {
    const bairrosContados = imoveis
      .filter(
        (imovel) =>
          selectedTipos.length === 0 || selectedTipos.includes(imovel.tipo)
      )
      .filter(
        (imovel) =>
          selectedCidades.length === 0 ||
          selectedCidades.includes(imovel.cidade)
      )
      .reduce((acc, imovel) => {
        acc[imovel.bairro] = (acc[imovel.bairro] || 0) + 1;
        return acc;
      }, {});

    const bairrosArray = Object.keys(bairrosContados).map((bairro) => ({
      nome: bairro,
      qtd: bairrosContados[bairro],
    }));

    setBairros(bairrosArray);
  };

  const handleTipoChange = (tipo) => {
    setSelectedTipos((prev) =>
      prev.includes(tipo) ? prev.filter((t) => t !== tipo) : [...prev, tipo]
    );
  };

  const handleCidadeChange = (cidade) => {
    setSelectedCidades((prev) =>
      prev.includes(cidade)
        ? prev.filter((c) => c !== cidade)
        : [...prev, cidade]
    );
  };

  const handleBairroChange = (bairro) => {
    setSelectedBairros((prev) =>
      prev.includes(bairro)
        ? prev.filter((b) => b !== bairro)
        : [...prev, bairro]
    );
  };

  const resetFiltro = () => {
    setSelectedTipos([]);
    setSelectedCidades([]);
    setSelectedBairros([]);
  };

  useEffect(() => {
    if (imoveis.length > 0) {
      updateTipos();
    } else {
      setTipos([]);
    }
  }, [imoveis]);

  useEffect(() => {
    updateCidades();

    if (selectedTipos.length === 0) {
      setSelectedCidades([]);
      setSelectedBairros([]);
    }
  }, [selectedTipos, imoveis]);

  useEffect(() => {
    updateBairros();

    if (selectedCidades.length === 0) {
      setSelectedBairros([]);
    }
  }, [selectedCidades, selectedTipos, imoveis]);

  return {
    resetFiltro,
    tipos,
    handleTipoChange,
    selectedTipos,
    cidades,
    handleCidadeChange,
    selectedCidades,
    bairros,
    handleBairroChange,
    selectedBairros,
    setSelectedTipos,
    setSelectedCidades,
  };
};

export default useFiltro;
