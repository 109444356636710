import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import HideImageIcon from "@mui/icons-material/HideImage";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";

import { Pagination, Navigation } from "swiper/modules";
import { Box, Typography } from "@mui/material";

export const PhotoSlider = ({ fotos }) => {
  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        slidesPerView={1}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        lazy="true"
      >
        {fotos.length > 0 ? (
          <>
            {fotos.map((foto, i) => (
              <SwiperSlide key={i}>
                <img src={foto} alt={`foto-${i}`} loading="lazy" />
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
              </SwiperSlide>
            ))}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "200px",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#ccc",
            }}
          >
            <HideImageIcon fontSize="large" />
            <Typography variant="button">Sem imagens no momento</Typography>
          </Box>
        )}
      </Swiper>
    </>
  );
};
