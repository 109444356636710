import React, { useEffect, useState } from "react";
import { Box, Button, Grid, MenuItem, Select, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import useImoveis from "../../hooks/useImoveis";

export const Pesquisar = () => {
  const theme = useTheme();
  const [motivo, setMotivo] = useState("");
  const [tipo, setTipo] = useState("");
  const [selectCidade, setSelectCidade] = useState("");
  const [cidades, setCidades] = useState([]);
  const [tipos, setTipos] = useState([]);

  const { getCidades, getTipos } = useImoveis();

  useEffect(() => {
    getCidades().then((result) => {
      setCidades(result);
    });

    getTipos().then((result) => {
      setTipos(result);
    });
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: `${theme.palette.primary.main}80`, // 50% de transparência
        padding: 3,
        borderRadius: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Select
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            value={motivo}
            onChange={(e) => setMotivo(e.target.value)}
            fullWidth
            sx={{
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            }}
          >
            <MenuItem value="">Locação / Venda</MenuItem>
            <MenuItem value="aluguel">Locação</MenuItem>
            <MenuItem value="venda">Venda</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={3}>
          <Select
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            value={tipo}
            onChange={(e) => setTipo(e.target.value)}
            fullWidth
            sx={{
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            }}
          >
            <MenuItem value="">Todas opções</MenuItem>
            {tipos.map((tipo, i) => (
              <MenuItem value={tipo} key={i}>
                {tipo.nome.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={3}>
          <Select
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            value={selectCidade}
            onChange={(e) => setSelectCidade(e.target.value)}
            fullWidth
            sx={{
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            }}
          >
            <MenuItem value="">Todas cidades</MenuItem>
            {cidades.map((cidade, i) => (
              <MenuItem value={cidade.nome} key={i}>
                {cidade.nome}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Link
              to={`/imoveis?buscaMotivo=${motivo}&buscaTipo=${tipo.nome}&buscaCidade=${selectCidade}`}
              style={{ width: "100%" }}
            >
              <Button
                size="large"
                variant="contained"
                sx={{
                  width: "100%",
                }}
              >
                Buscar
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
