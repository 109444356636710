import { useEffect, useState } from "react";
import useImoveis from "../../hooks/useImoveis";
import { BaseDePagina } from "../../layout/BaseDePagina";
import { AppHeader, ImovelBox } from "../../components";
import { Toolbar, Grid, Box, Paper, Typography } from "@mui/material";

export const Buscar = () => {
  const [imoveis, setImoveis] = useState([]);

  const { getImoveis } = useImoveis();

  const handleGetImoveis = async () => {
    await getImoveis().then((result) => {
      setImoveis(result);
    });
  };

  useEffect(() => {
    handleGetImoveis();
  }, []);

  return (
    <Box>
      <AppHeader />
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={2}>
          <Box
            component={Paper}
            sx={{
              p: 2,
              height: "100vh",
              position: "sticky",
              top: 0,
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
            }}
          >
            <Typography variant="h6">Filtros</Typography>
            {/* Adicione os elementos do filtro aqui */}
          </Box>
        </Grid>

        {/* Lista de imóveis - 80% da página */}
        <Grid item xs={12} md={9} lg={10}>
          <Box
            sx={{
              height: "calc(100vh - 64px)", // Ajuste conforme necessário
              overflowY: "auto",
              pr: 2,
            }}
          >
            {imoveis.map((imovel, i) => (
              <ImovelBox dados={imovel} key={i} />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
