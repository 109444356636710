import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { BaseDePagina } from "../../layout/BaseDePagina";
import { TextHeading } from "../../components";

export const Sobre = () => {
  return (
    <BaseDePagina>
      <TextHeading text="Sobre nós" />
      <Container sx={{ mb: 3 }}>
        <Paper elevation={5} sx={{ mt: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box sx={{ padding: 5 }}>
                <Typography variant="h6" mb={3} color="primary">
                  Fortulino Imobiliária
                </Typography>
                <Typography variant="subtitle2" mb={3}>
                  A Fortulino Imobiliária nasceu do sonho de transformar a
                  experiência de encontrar o lar ideal em algo simples, seguro e
                  completamente personalizado. Com mais de uma década de
                  expertise no mercado imobiliário, nossa missão é conectar
                  pessoas a espaços que não apenas atendam suas necessidades,
                  mas que também reflitam suas personalidades e estilos de vida.
                </Typography>
                <Typography variant="subtitle2" mb={3}>
                  Nossa equipe é composta por profissionais apaixonados e
                  altamente qualificados, dedicados a oferecer um atendimento
                  excepcional desde o primeiro contato até a entrega das chaves.
                  Entendemos que cada cliente é único, e é por isso que adotamos
                  uma abordagem personalizada, analisando detalhadamente suas
                  preferências, necessidades e sonhos para encontrar a
                  propriedade perfeita.
                </Typography>
                <Typography variant="subtitle2" mb={3}>
                  Na Fortulino Imonibiária, a transparência e a integridade são
                  os pilares de nosso trabalho. Nos comprometemos a fornecer
                  todas as informações necessárias e suporte completo em todas
                  as etapas do processo de compra, venda ou aluguel, garantindo
                  uma experiência sem estresse e totalmente segura.
                </Typography>
                <Typography variant="subtitle2">
                  Nos orgulhamos de construir relações duradouras com nossos
                  clientes, baseadas na confiança e na satisfação. Seja você um
                  comprador de primeira viagem, um investidor imobiliário
                  experiente ou alguém em busca de um novo lar para alugar,
                  estamos aqui para tornar seu sonho realidade. Bem-vindo à
                  Fortulino Imobiliária, onde o seu lar dos sonhos espera por
                  você.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  padding: 2,
                }}
              >
                <img
                  alt="sobre-img"
                  src="https://images.unsplash.com/photo-1622790410882-c1f00a56083e?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  style={{
                    width: "100%", // Faz a imagem ocupar 100% da largura do Box
                    height: "100%", // Faz a imagem ocupar 100% da altura do Box
                    objectFit: "contain", // Mantém as proporções e garante que a imagem seja completamente visível
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </BaseDePagina>
  );
};
