import {
  collection,
  getDocs,
  getCountFromServer,
  query,
  startAfter,
  where,
  limit,
  addDoc,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { useCallback, useState } from "react";
import { db } from "../services/firebase-config/firebaseConfig";
import { useLoading } from "../contexts/LoadingContext";
import { toast } from "react-toastify";

const useImoveis = () => {
  const { setLoading } = useLoading();
  const [total, setTotal] = useState(0);

  const getImoveis = useCallback(
    async (pageSize, lastDoc = null, motivo) => {
      setLoading(true);
      try {
        let q;
        const baseQuery = query(
          collection(db, "imoveis"),
          where("situacao", "==", "disponivel")
        );

        if (motivo) {
          q = query(
            baseQuery,
            where("motivo", "==", motivo),
            ...(lastDoc ? [startAfter(lastDoc)] : []),
            limit(pageSize)
          );
        } else {
          q = query(
            baseQuery,
            ...(lastDoc ? [startAfter(lastDoc)] : []),
            limit(pageSize)
          );
        }

        const snapshot = await getDocs(q);
        const dataList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const lastVisible = snapshot.docs[snapshot.docs.length - 1];

        return { dataList, lastVisible };
      } finally {
        setLoading(false);
      }
    },
    [setLoading]
  );

  const getImovelById = useCallback(async (id) => {
    setLoading(true);
    try {
      const docRef = doc(db, "imoveis", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        toast.error("Imóvel não existe");
      }
    } catch (error) {
      console.log("Erro ao enviar mensagem", error);
      toast.error("Imóvel não encontrado");
    } finally {
      setLoading(false);
    }
  }, []);

  const getTotalImoveis = useCallback(async () => {
    const snapshot = await getCountFromServer(collection(db, "imoveis"));
    setTotal(snapshot.data().count);
  }, []);

  const getImoveisDestaque = useCallback(async () => {
    setLoading(true);
    try {
      const imoveis = [];
      const q = query(
        collection(db, "imoveis"),
        where("destaque", "==", true),
        where("situacao", "==", "disponivel")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        imoveis.push(doc.data());
      });
      return imoveis;
    } finally {
      setLoading(false);
    }
  }, []);

  const getCidades = useCallback(async () => {
    setLoading(true);
    try {
      const cidades = {};
      const q = query(collection(db, "imoveis"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.cidade) {
          if (cidades[data.cidade]) {
            cidades[data.cidade] += 1;
          } else {
            cidades[data.cidade] = 1;
          }
        }
      });
      const cidadesArray = Object.keys(cidades).map((cidade) => ({
        nome: cidade,
        qtd: cidades[cidade],
      }));

      return cidadesArray;
    } finally {
      setLoading(false);
    }
  }, []);

  const getTipos = useCallback(async () => {
    try {
      const tipos = {};
      const q = query(collection(db, "imoveis"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.tipo) {
          if (tipos[data.tipo]) {
            tipos[data.tipo] += 1;
          } else {
            tipos[data.tipo] = 1;
          }
        }
      });
      const tiposArray = Object.keys(tipos).map((tipo) => ({
        nome: tipo,
        qtd: tipos[tipo],
      }));

      return tiposArray;
    } finally {
      setLoading(false);
    }
  }, []);

  const getSiteInfo = useCallback(async () => {
    try {
      const docRef = await getDoc(doc(db, "imoveisConfig", "Site"));
      return docRef.data();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const enviarMensagem = async (data) => {
    setLoading(true);
    try {
      const docRef = await addDoc(collection(db, "msgJfImoveis"), data);

      await setDoc(doc(db, "msgJfImoveis", docRef.id), {
        ...data,
        id: docRef.id,
        lida: false,
      });

      toast.success("Mensagem enviada com sucesso!");
    } catch (error) {
      console.log("Erro ao enviar mensagem", error);
      toast.error("Erro ao enviar a mensagem");
    } finally {
      setLoading(false);
    }
  };

  return {
    getImoveis,
    getImovelById,
    getImoveisDestaque,
    getCidades,
    getTipos,
    getSiteInfo,
    getTotalImoveis,
    total,
    enviarMensagem,
  };
};

export default useImoveis;
