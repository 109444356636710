import { BaseDePagina } from "../../layout/BaseDePagina";

import { Banner, Destaques } from "../../components";

export const Home = () => {
  return (
    <BaseDePagina>
      <Banner />
      <Destaques />
    </BaseDePagina>
  );
};
