import { Grid, IconButton, Link, Typography, Box } from "@mui/material";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useTheme } from "@emotion/react";
import useImoveis from "../../hooks/useImoveis";
import { useEffect, useState } from "react";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {"Copyright © "}
      <Link color="inherit" href="https://jfortulinoimoveis.com.br/">
        J Fortulino Imóveis
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export const Footer = () => {
  const [info, setInfo] = useState(null);
  const { getSiteInfo } = useImoveis();

  const theme = useTheme();

  const handleGetInfo = async () => {
    const result = await getSiteInfo();
    setInfo(result);
  };

  useEffect(() => {
    handleGetInfo();
  }, []);

  return (
    <Box
      component="footer"
      sx={{
        width: "100%",
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <Grid container>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              src={require(`../../assets/logo${
                theme.palette?.mode === "dark" ? "White" : ""
              }.png`)}
              alt="logo"
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h5" fontWeight="bold" color="primary">
              FORTULINO EMPREENDIMENTOS
            </Typography>
            <Typography variant="subtitle">Imobiliária</Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography variant="subtitle" fontWeight={500}>
                CRECI:
              </Typography>
              <Typography variant="subtitle">{info?.creci}</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                marginTop: 2,
              }}
            >
              <WhatsAppIcon />
              <Typography>{info?.whatsapp}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                marginBottom: 2,
              }}
            >
              <LocalPhoneIcon />
              <Typography>{info?.telefone}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                marginBottom: 2,
              }}
            >
              {info?.emailPrincipal && (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <EmailIcon />
                  <Typography>{info?.emailPrincipal}</Typography>
                </Box>
              )}
              {info?.emailSecundario && (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <EmailIcon />
                  <Typography>{info?.emailSecundario}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h5" fontWeight="bold" color="primary">
              REDES SOCIAIS
            </Typography>
            <Typography variant="subtitle">Nos acompanhe</Typography>

            <Box sx={{ display: "flex" }}>
              <Link href={`https://${info?.instagram}`} target="_blank">
                <IconButton>
                  <InstagramIcon />
                </IconButton>
              </Link>

              <Link href={`https://${info?.facebook}`} target="_blank">
                <IconButton>
                  <FacebookIcon />
                </IconButton>
              </Link>
              <Link href={`https://${info?.youtube}`} target="_blank">
                <IconButton>
                  <YouTubeIcon />
                </IconButton>
              </Link>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              marginBottom: 2,
            }}
          >
            <Typography variant="h5" fontWeight="bold" color="primary">
              ENDEREÇO
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <LocationOnIcon />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography>{`${info?.enderecoLogradouro}, ${info?.enderecoNumero}, ${info?.enderecoBairro}`}</Typography>
                <Typography>{`${info?.enderecoCep} - ${info?.enderecoCidade}/${info?.enderecoUf}`}</Typography>
                <Typography
                  fontSize={14}
                >{`${info?.enderecoComplemento}`}</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 3,
            }}
          >
            <Copyright />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

/*
   <Grid container>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography variant="h5">Sua Logo</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
     
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h5" fontWeight="bold" color="primary">
              REDES SOCIAIS
            </Typography>
            <Typography variant="subtitle">Nos acompanhe</Typography>

            <Box sx={{ display: "flex" }}>
              <Link
                href="https://www.instagram.com/magazinefortulino/"
                target="_blank"
              >
                <IconButton>
                  <InstagramIcon />
                </IconButton>
              </Link>

              <Link
                href="https://www.facebook.com/magazinefortulino/"
                target="_blank"
              >
                <IconButton>
                  <FacebookIcon />
                </IconButton>
              </Link>
              <Link
                href="https://www.youtube.com/magazinefortulino/"
                target="_blank"
              >
                <IconButton>
                  <YouTubeIcon />
                </IconButton>
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 3,
            }}
          >
            <Copyright />
          </Box>
        </Grid>
      </Grid>
*/
