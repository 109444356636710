import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Icon,
  IconButton,
  TextField,
  Typography,
  Button,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";

export const Filtro = ({
  resetFiltro,
  tipos,
  handleTipoChange,
  selectedTipos,
  cidades,
  handleCidadeChange,
  selectedCidades,
  bairros,
  handleBairroChange,
  selectedBairros,
  motivo,
  setMotivo,
}) => {
  const handleMotivoChange = (event, motivo) => {
    setMotivo(motivo);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
        <Typography variant="h6" fontWeight={600} fontSize={18}>
          Buscar por código
        </Typography>
        <Box sx={{ display: "flex", gap: 1, padding: 1 }} component={Paper}>
          <TextField size="small" placeholder="Digite aqui..." fullWidth />
          <IconButton color="primary">
            <Icon>search</Icon>
          </IconButton>
        </Box>
      </Box>
      <ToggleButtonGroup
        fullWidth
        sx={{ my: 1 }}
        color="primary"
        exclusive
        value={motivo}
        onChange={handleMotivoChange}
      >
        <ToggleButton value="">TODOS</ToggleButton>
        <ToggleButton value="aluguel">ALUGUEL</ToggleButton>
        <ToggleButton value="venda">VENDA</ToggleButton>
      </ToggleButtonGroup>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h6" fontSize={18}>
          Filtro:
        </Typography>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
            <Typography fontWeight={600}>Tipo de imóvel</Typography>
          </AccordionSummary>
          {tipos.length > 0 ? (
            <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
              <FormGroup>
                {tipos.map((tipo, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        checked={selectedTipos.includes(tipo.nome)}
                        onChange={() => handleTipoChange(tipo.nome)}
                      />
                    }
                    label={`${tipo.nome.toUpperCase()} (${tipo.qtd})`}
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          ) : (
            <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
              <Typography>Nenhum imóvel disponível</Typography>
            </AccordionDetails>
          )}
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
            <Typography fontWeight={600}>Cidades</Typography>
          </AccordionSummary>
          {selectedTipos.length > 0 ? (
            <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
              <FormGroup>
                {cidades.map((tipo, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        checked={selectedCidades.includes(tipo.nome)}
                        onChange={() => handleCidadeChange(tipo.nome)}
                      />
                    }
                    label={`${tipo.nome.toUpperCase()} (${tipo.qtd})`}
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          ) : (
            <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
              <Typography>Selecione um tipo de imóvel</Typography>
            </AccordionDetails>
          )}
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<Icon>expand_more</Icon>}>
            <Typography fontWeight={600}>Bairros</Typography>
          </AccordionSummary>
          {selectedCidades.length > 0 ? (
            <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
              <FormGroup>
                {bairros.map((tipo, i) => (
                  <FormControlLabel
                    key={i}
                    control={
                      <Checkbox
                        checked={selectedBairros.includes(tipo.nome)}
                        onChange={() => handleBairroChange(tipo.nome)}
                      />
                    }
                    label={`${tipo.nome.toUpperCase()} (${tipo.qtd})`}
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          ) : (
            <AccordionDetails sx={{ display: "flex", flexDirection: "column" }}>
              <Typography>Selecione um tipo de imóvel</Typography>
            </AccordionDetails>
          )}
        </Accordion>
      </Box>

      <Button
        sx={{ mt: 2 }}
        variant="contained"
        color="inherit"
        onClick={resetFiltro}
      >
        Limpar filtro
      </Button>
    </Box>
  );
};
